import React from 'react'

import '../styles/icomoon.css'
import '../styles/socials.sass'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome' 
import {
    faFacebookF,
    faGithub,
    faDiscord,
    faLinkedinIn,
    faYoutube,
    faXTwitter,
    faInstagram
} from '@fortawesome/free-brands-svg-icons'


const Socials = () => (
    <div className="socials-container">
        <SocialButton icon={faGithub} link="https://github.com/polycyber" colour="#333"/>
        <SocialButton icon={faDiscord} link="https://discord.gg/3Z8J6Z8" colour="#7289DA"/>
        <SocialButton icon={faInstagram} link="https://www.instagram.com/polycyber_polymtl/" colour="#E1306C"/>
        <SocialButton icon={faLinkedinIn} link="https://www.linkedin.com/company/polyhxcyber" colour="#0077b5"/>
        <SocialButton icon={faYoutube} link="https://www.youtube.com/@polycyber/" colour="#FF0000"/>
        <SocialButton icon={faXTwitter} link="https://x.com/polyhxcyber" colour="#000000"/>
    </div>
)

const SocialButton = ({ icon, link, colour }) => (
    <a href={link} >
        <div style={{ background: colour, color: 'white' }} className="social-button">
            <div className="icon">
                <FontAwesomeIcon icon={icon} />
            </div>
        </div>
    </a>
)

export default Socials
